import {get} from 'lodash';
import Role from '@/library/enumerations/Role';

export default {
    methods: {
        /**
         * Redirects the user to the default dashboard page based on role:
         * ADMIN: 'groups.index'
         * COACH: 'programs.index'
         * fallback: /users/{current-user-id}/groups (Group admins)
         * @return {Promise<Route>}
         */
        redirectAuthenticated(origin) {
            const redirectBackTo = this.$route.query.authRedirectBackTo;

            if (redirectBackTo) {
                return this.$router.replace(redirectBackTo);
            }

            const query = this.$route.query;

            if (origin !== undefined) {
                query.origin = origin;
            }

            if (this.$me.hasRoles(Role.ADMIN)) {
                return this.$router.replace({...this.$route, name: 'groups.index', query});
            }

            if (this.$me.hasRoles(Role.COACH)) {
                return this.$router.replace({...this.$route, ...this.$me.getLocation('programs.index'), query});
            }

            return this.$router.replace({...this.$route, ...this.$me.getLocation('groups.index'), query});
        },
    },
};
