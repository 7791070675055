<template>
    <div class="d-flex flex-column min-vh-100">
        <div class="sticky-top">
            <navbar-default />

            <div class="bg-white position-relative">
                <b-container>
                    <b-row
                        align-v="end"
                        style="min-height: 11rem;"
                    >
                        <b-col class="py-5">
                            <portal-target
                                name="layout-default-header"
                                slim
                            />
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </div>

        <div class="d-flex position-relative overflow-hidden flex-grow-1">
            <svg-circle class="stroke-white position-absolute top-left" />

            <svg-circle class="stroke-white position-absolute bottom-right" />

            <b-container>
                <b-row class="h-100">
                    <b-col class="py-4 py-xl-5">
                        <router-view />
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import SvgCircle from '@/components/common/SvgCircle';
import NavbarDefault from '@/components/layouts/NavbarDefault';

export default {
    name: 'Default',
    components: {NavbarDefault, SvgCircle},
};
</script>

<style lang="scss" scoped>
.top-left {
    top: 0;
    left: 0;
    transform: translate(-30%, -70%);
}

.bottom-right {
    right: 0;
    bottom: 0;
    transform: translate(43%, 53%);
}
</style>
