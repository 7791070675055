<template>
    <div v-if="!!groupId">
        <b-nav-item
            exact-active-class="active"
            :to="routeMembers"
        >
            {{ $t('nav.groups.clients') }}
        </b-nav-item>

        <b-nav-item
            :to="routeGroup"
            :link-classes="{active: groupsShowActive}"
        >
            {{ $t('nav.groups.show') }}
        </b-nav-item>

        <!-- <b-nav-item
            exact-active-class="active"
            :to="{name: 'groups.statistics.index'}"
        >
            {{ $t('nav.groups.statistics') }}
        </b-nav-item> -->
    </div>
</template>

<script>
export default {
    name: 'NavItemsGroupAdmin',
    computed: {
        groupId() {
            const routeId = this.$route.name.indexOf('groups') > -1
                && this.$route.params
                ? this.$route.params.id
                : undefined;

            return routeId
                || (this.$me.company ? this.$me.company.id : undefined);
        },
        /**
         * Manually set to active so other child routes of the same parent will
         * not be seen as active.
         */
        groupsShowActive() {
            return [
                'groups.edit',
                'groups.admins',
                'groups.coaches',
                'groups.clients',
            ].includes(this.$route.name);
        },
        routeMembers() {
            return this.groupRoute('groups.members');
        },
        routeGroup() {
            return this.groupRoute('groups.edit');
        },
    },
    methods: {
        groupRoute(name) {
            return {
                name,
                params: {id: this.groupId},
            };
        },
    },
};
</script>
