<template>
    <div>
        <b-nav-item
            active-class="active"
            :to="{name: 'users.index'}"
        >
            {{ $t('nav.users.index') }}
        </b-nav-item>

        <b-nav-item
            active-class="active"
            :to="{name: 'groups.index'}"
        >
            {{ $t('nav.groups.index') }}
        </b-nav-item>

        <b-nav-item
            active-class="active"
            :to="{name: 'posts.index'}"
        >
            {{ $t('posts.index.nav') }}
        </b-nav-item>

        <!-- Hidden for now because this is unquoted. -->
        <!-- <b-nav-item disabled>
            {{ $t('nav.groups.coaches') }}
        </b-nav-item> -->
    </div>
</template>

<script>
export default {
    name: 'NavItemsAdmin',
};
</script>
