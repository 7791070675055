export default {
    methods: {
        /**
         * Show modal confirm which return resolved value:
         * - true, if 'confirm' clicked
         * - false, if 'cancel' clicked
         * - null, if modal is closed via backdrop click
         *
         * @param {string} message
         * @param {BvMsgBoxOptions} options
         * @return {Promise<boolean|null>}
         */
        showModalConfirm(message, options = {}) {
            const mergedOptions = {
                title: this.$t('common.words.confirmation'),
                centered: true,
                size: 'sm',
                autoFocusButton: 'ok',
                // Allow component to override the default options above.
                ...options,
            };

            return this.$bvModal.msgBoxConfirm(message, mergedOptions);
        },
    },
};
