<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 504 503"
        width="504"
        height="503"
        stroke="none"
        fill="none"
    >
        <path
            d="M252 9c134.205 0 243 108.52 243 242.386S386.205 493.773 252 493.773 9 385.253 9 251.386 117.795 9 252 9z"
            stroke-width="16.6"
            stroke-dasharray="26.31999969482422,28.36000061035156"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'SvgCircle',
};
</script>
