<template>
    <b-navbar
        v-tour="{tour: 'coach-intro', identifier: 'navigation', priority: 30}"
        class="bg-white border-bottom p-md-0"
        toggleable="md"
    >
        <b-navbar-brand
            class="ml-md-3"
            tag="h2"
            @click="onClickBrand"
        >
            {{ $t('nav.brand') }}
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" />

        <b-collapse
            id="nav-collapse"
            class="justify-content-end"
            is-nav
        >
            <b-navbar-nav
                v-if="navComponent"
                class="navbar-default-center"
            >
                <component
                    :is="navComponent"
                    class="d-inline-flex"
                />
            </b-navbar-nav>

            <div class="d-flex">
                <b-button
                    v-if="$me"
                    v-tour="{tour: 'coach-intro', identifier: 'profile', priority: 20}"
                    :to="$me.getLocation('edit')"
                    class="d-inline-flex align-items-center font-size-base"
                    variant="light"
                >
                    <span class="font-weight-bold mr-3">
                        {{ $me.name }}
                    </span>

                    <fa
                        class="text-primary no-fill stroke"
                        :icon="['fac', 'arrow-right']"
                    />
                </b-button>

                <b-button
                    class="navbar-default-square font-size-base border-left"
                    variant="light"
                    @click="onClickLogout"
                >
                    <fa
                        class="no-fill stroke"
                        :icon="['fac', 'logout']"
                        size="lg"
                    />
                </b-button>
            </div>
        </b-collapse>
    </b-navbar>
</template>

<script>
import Role from '@/library/enumerations/Role';
import NavItemsAdmin from '@/components/layouts/NavItemsAdmin';
import NavItemsCoach from '@/components/layouts/NavItemsCoach';
import NavItemsGroupAdmin from '@/components/layouts/NavItemsGroupAdmin';
import ShowModalConfirm from '@/components/common/mixins/ShowModalConfirm';
import RedirectsAuthenticated from '@/components/auth/mixins/RedirectsAuthenticated';

export default {
    name: 'NavbarDefault',
    mixins: [RedirectsAuthenticated, ShowModalConfirm],
    computed: {
        /**
         * Returns the navigation items component based on the current users role:
         * ADMIN: {@link NavItemsAdmin}
         * COACH: {@link NavItemsCoach}
         * fallback: {@link NavItemsGroupAdmin}
         */
        navComponent: function() {
            if (!this.$me) {
                return;
            }

            if (this.$me.hasRoles(Role.ADMIN)) {
                return NavItemsAdmin;
            }

            if (this.$me.hasRoles(Role.COACH)) {
                return NavItemsCoach;
            }

            return NavItemsGroupAdmin;
        },
    },
    methods: {
        onClickBrand() {
            this.redirectAuthenticated();
        },
        async onClickLogout() {
            const confirmed = await this.showModalConfirm(
                this.$t('common.messages.logoutConfirmation'),
            );

            if (confirmed) {
                this.$router.push({name: 'auth.logout'}).catch(error => {});
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-light {
    border-width: 0;
    border-radius: 0;

    &:not(:hover) {
        background: transparent;
    }
}

.navbar-default-center {
    @include position-absolute-center(true, false);
}

.navbar-default-square {
    width: 3.5rem;
}
</style>
