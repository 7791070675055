<template>
    <div>
        <b-nav-item
            :active="$route.name.startsWith('programs')"
            active-class="active"
            :to="$me.getLocation('programs.index')"
        >
            {{ $t('users.programs.index.nav') }}
        </b-nav-item>

        <b-nav-item
            :active="$route.name.startsWith('clients')"
            active-class="active"
            :to="$me.getLocation('members.index')"
        >
            {{ $t('users.members.index.nav') }}
        </b-nav-item>

        <b-nav-item
            active-class="active"
            :to="{name: 'conversations.index'}"
        >
            {{ $t('conversations.index.title') }}
        </b-nav-item>

        <b-nav-item
            active-class="active"
            :to="{name: 'posts.index'}"
        >
            {{ $t('posts.index.nav') }}
        </b-nav-item>
    </div>
</template>

<script>
export default {
    name: 'NavItemsCoach',
};
</script>
